import React, { useState, useEffect, useRef } from 'react';
import { Box, Grid } from '@mui/material';
import axios from 'axios';

const FloorPlan = () => {
  const [content, setContent] = useState(null);
  const boxRef = useRef();

  useEffect(() => {
    axios
      .get(`/rotr/floor-plan/content`)
      .then((res) => {
        setContent(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const calculateDistance = () => {
    if (boxRef.current) {
      const boxRect = boxRef.current.getBoundingClientRect();
      const viewportHeight = window.innerHeight;
      const distanceToBottom = viewportHeight - boxRect.top - 64;
      boxRef.current.style.height = `${Math.max(distanceToBottom, 650)}px`;
    }
  };

  useEffect(() => {
    if (content) {
      content.forEach((entry) => {
        const element = document.getElementById(entry.content_code);
        if (element) {
          element.innerHTML = entry.content;
        }
      });
      calculateDistance();
    }
  }, [content]);

  useEffect(() => {
    calculateDistance();

    window.addEventListener('resize', calculateDistance);

    return () => {
      window.removeEventListener('resize', calculateDistance);
    };
  }, []);

  return (
    <Box sx={{}}>
      <Grid container>
        <Grid item container xs={12} id='FLOOR_PLAN_ROW1_COL1' />
        <Grid item container xs={12} columnSpacing={2} alignItems='flex-start'>
          <Grid item xs={12}>
            <Box ref={boxRef} sx={{ width: '100%' }}>
              <iframe
                src='https://rotr.expofp.com/?kiosk=1'
                width='100%'
                height='100%'
                title='Expo Floor Plan'
                style={{ border: 'none' }}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FloorPlan;
