import React, { useState, useEffect, useRef } from 'react';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import ViewRegistrationModal from '../ViewRegistrationModal';
import BadgePreviewModal from '../BadgePreviewModal';
import ConfirmDeleteModal from '../ConfirmDeleteModal';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { setExhibitors } from '../../../Redux/ExhibitorSlice';
import ERegistrationTableDesktop from './ERegistrationTableDesktop';
import ERegistrationTableMobile from './ERegistrationTableMobile';
import { useSelector } from 'react-redux';
import {
  clearExhibitorRegistration,
  setExhibitorRegistration,
} from '../../../Redux/ExhibitorRegistrationSlice';
import { setModalStatus } from '../../../Redux/ModalSlice';
import ProfileExplanationModal from './ProfileExplanationModal';
import { setBlockNavigation } from '../../../Redux/BlockNavigationSlice';
import BadgeConfirmationEmail from '../BadgeConfirmationEmail';
import { isValidEmail, isValidPhone, trimIfString } from '../../../_GlobalFunctions/InfoValidation';

const ERegistrationTable = ({ userInfo }) => {
  const [expandedPersonnelIds, setExpandedPersonnelIds] = useState({});
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [selectedExhibitorId, setSelectedExhibitorId] = useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const [accordionExpanded, setAccordionExpanded] = useState([]);
  const [selectedPersonnel, setSelectedPersonnel] = useState('');
  const [badgeErrors, setBadgeErrors] = useState({});
  const [selectedBrandForDelete, setSelectedBrandForDelete] = useState(null);
  const [isConfirmDeleteBrandModalOpen, setIsConfirmDeleteBrandModalOpen] = useState(false);
  const [selectedPersonnelForDelete, setSelectedPersonnelForDelete] = useState(null);
  const [isConfirmDeletePersonnelModalOpen, setIsConfirmDeletePersonnelModalOpen] = useState(false);
  const exhibitors = useSelector((state) => state.exhibitors.value);
  const [editingRows, setEditingRows] = useState([]);
  const [states, setStates] = useState([]);
  const dispatch = useDispatch();
  const [selectedLink, setSelectedLink] = useState('');
  const highlightPersonnelAdd = useSelector((state) => state.modalStatus.exhibitorBadges);
  const [expandedBrandIds, setExpandedBrandIds] = useState(
    highlightPersonnelAdd ? exhibitors.map((ex) => ex.id) : []
  );
  const tableRef = useRef(null);
  const exhibitorRegistration = useSelector((state) => state.registration);
  const isAddingBooth = useSelector((state) => state.modalStatus.isAddingBooth);
  const isEditingBooth = useSelector((state) => state.registration.isEditing);

  useEffect(() => {
    if (highlightPersonnelAdd) {
      const timer = setTimeout(() => {
        dispatch(setModalStatus({ exhibitorBadges: false }));
      }, 10000);

      const scrollInterval = setInterval(() => {
        if (tableRef.current) {
          tableRef.current.scrollIntoView({ behavior: 'smooth' });
          clearInterval(scrollInterval);
        }
      }, 100);

      return () => {
        clearTimeout(timer);
        clearInterval(scrollInterval);
      };
    }
  }, [highlightPersonnelAdd, dispatch]);

  const getStates = () => {
    axios
      .get('/rotr/states')
      .then((res) => {
        setStates(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getStates();
  }, []);

  useEffect(() => {
    dispatch(
      setBlockNavigation({
        origin: 'e-registration',
        when: editingRows.length > 0 || isAddingBooth || isEditingBooth,
        message: 'You have unsaved changes. Are you sure you want to leave?',
      })
    );
  }, [dispatch, editingRows.length, isAddingBooth, isEditingBooth]);

  const deleteBrand = (brandId) => {
    const exhibitor = exhibitors.find((brand) => brand.id === brandId);

    axios
      .post(
        '/rotr/exhibitors/deleteExhibitorBoothExpoFP',
        {},
        { params: { exhibitorId: exhibitor.expofp_id_number, boothName: exhibitor.booth_number } }
      )
      .then(() => {
        return axios.post(
          '/rotr/exhibitors/deleteExhibitorExpoFP',
          {},
          {
            params: {
              exhibitorId: exhibitor.expofp_id_number,
            },
          }
        );
      })
      .then(() => {
        return axios.delete('/rotr/exhibitors', { params: { exhibitorId: brandId } });
      })
      .then(() => {
        dispatch(setExhibitors(exhibitors.filter((brand) => brand.id !== brandId)));
        if (exhibitorRegistration.exhibitorId === brandId) {
          dispatch(clearExhibitorRegistration());
        }
      })
      .catch((err) => {
        console.error('Failed to delete exhibitor:', err);
      });
  };

  const addPersonnel = (brandId, personnelId) => {
    dispatch(
      setExhibitors(
        exhibitors.map((brand) =>
          brand.id === brandId
            ? {
                ...brand,
                personnel: [
                  ...brand.personnel,
                  {
                    badge_id: personnelId,
                    first_name: '',
                    last_name: '',
                    job_title: '',
                    company_name: brand.personnel[0]?.company_name ?? '',
                    email_address: '',
                    phone_number: '',
                    city: brand.personnel[0]?.city ?? '',
                    state_id: brand.personnel[0]?.state_id ?? null,
                    state: brand.personnel[0]?.state ?? null,
                  },
                ],
              }
            : brand
        )
      )
    );
    setEditingRows([...editingRows, personnelId]);
    setTimeout(() => {
      const tableElement = document.getElementById(`table_${brandId}`);
      if (tableElement) {
        tableElement.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        });
      }
    }, 100);
  };

  const deletePersonnel = ({ brandId, personnelId }) => {
    if (!String(personnelId).includes('temp_')) {
      deletePersonnelQuery(brandId, personnelId);
    } else {
      dispatch(
        setExhibitors(
          exhibitors.map((brand) =>
            brand.id === brandId
              ? {
                  ...brand,
                  personnel: brand.personnel.filter((person) => person.badge_id !== personnelId),
                }
              : brand
          )
        )
      );
      setEditingRows(editingRows.filter((id) => id !== personnelId));
    }
  };

  const handlePersonnelInputChange = (brandId, personnelId, field, value) => {
    if (badgeErrors.hasOwnProperty(field)) {
      setBadgeErrors((prev) => {
        const otherErrors = prev[personnelId] ?? {};
        return {
          ...prev,
          [personnelId]: {
            ...otherErrors,
            [field]: false,
          },
        };
      });
    }
    dispatch(
      setExhibitors(
        exhibitors.map((brand) =>
          brand.id === brandId
            ? {
                ...brand,
                personnel: brand.personnel.map((person) =>
                  person.badge_id === personnelId ? { ...person, [field]: value } : person
                ),
              }
            : brand
        )
      )
    );
  };

  const handlePersonnelStateChange = (brandId, personnelId, state_id, state) => {
    setBadgeErrors((prev) => {
      const otherErrors = prev[personnelId] ?? {};
      return {
        ...prev,
        [personnelId]: {
          ...otherErrors,
          state_id: false,
        },
      };
    });
    dispatch(
      setExhibitors(
        exhibitors.map((brand) =>
          brand.id === brandId
            ? {
                ...brand,
                personnel: brand.personnel.map((person) =>
                  person.badge_id === personnelId
                    ? { ...person, state_id: state_id, state: state }
                    : person
                ),
              }
            : brand
        )
      )
    );
  };

  const handleEdit = (personnelId) => {
    setEditingRows([...editingRows, personnelId]);
  };

  const handleSave = (brandId, personnelId) => {
    if (String(personnelId).includes('temp_')) {
      addPersonnelQuery(brandId, personnelId);
    } else {
      updatePersonnelQuery(brandId, personnelId);
    }
  };

  const handleExpandClick = (brandId) => {
    setExpandedBrandIds((prev) =>
      prev.includes(brandId) ? prev.filter((id) => id !== brandId) : [...prev, brandId]
    );
  };

  const handlePersonnelExpandClick = (brandId, personId) => {
    setExpandedPersonnelIds((prev) => ({
      ...prev,
      [brandId]: prev[brandId]
        ? prev[brandId].includes(personId)
          ? prev[brandId].filter((id) => id !== personId)
          : [...prev[brandId], personId]
        : [personId],
    }));
  };

  const handleAddPersonnel = (brandId) => {
    const personnelId = `temp_${Math.random().toString(36).substring(2, 9)}`;
    addPersonnel(brandId, personnelId);
    setExpandedPersonnelIds((prev) => ({
      ...prev,
      [brandId]: prev[brandId] ? [...prev[brandId], personnelId] : [personnelId],
    }));
    setAccordionExpanded((prev) => {
      const newVal = [...prev];
      if (!prev.includes(brandId)) {
        newVal.push(brandId);
      }
      return newVal;
    });
  };

  const handleViewRegistrationModal = (exhibitorId) => {
    setSelectedExhibitorId(exhibitorId);
    setIsViewModalOpen(!isViewModalOpen);
  };

  const handleBadgeModal = (person) => {
    if (person) {
      setSelectedPersonnel(person);
      dispatch(setModalStatus({ badgeModal: true }));
    }
  };

  const handleDeleteClick = (brandId) => {
    setSelectedBrandForDelete(brandId);
    setIsConfirmDeleteBrandModalOpen(true);
  };

  const handleConfirmDelete = () => {
    if (selectedBrandForDelete) {
      deleteBrand(selectedBrandForDelete);
      setIsConfirmDeleteBrandModalOpen(false);
      setSelectedBrandForDelete(null);
    }
  };

  const handleCancelDelete = () => {
    setIsConfirmDeleteBrandModalOpen(false);
    setSelectedBrandForDelete(null);
  };

  const handleDeletePersonnelClick = (brandId, personnelId) => {
    setSelectedPersonnelForDelete({ brandId: brandId, personnelId: personnelId });
    setIsConfirmDeletePersonnelModalOpen(true);
  };

  const handleConfirmDeletePersonnel = () => {
    if (selectedPersonnelForDelete) {
      deletePersonnel(selectedPersonnelForDelete);
      setIsConfirmDeletePersonnelModalOpen(false);
      setSelectedPersonnelForDelete(null);
    }
  };

  const handleCancelDeletePersonnel = () => {
    setIsConfirmDeletePersonnelModalOpen(false);
    setSelectedPersonnelForDelete(null);
  };

  const addPersonnelQuery = (brandId, personnelId) => {
    const person = exhibitors
      .find((brand) => brand.id === brandId)
      .personnel.find((person) => person.badge_id === personnelId);
    const trimmedFirstName = trimIfString(person.first_name);
    const trimmedLastName = trimIfString(person.last_name);
    const trimmedJobTitle = trimIfString(person.job_title);
    const trimmedCompanyName = trimIfString(person.company_name);
    const trimmedCity = trimIfString(person.city);
    const trimmedEmailAddress = trimIfString(person.email_address);

    axios
      .post('/rotr/exhibitors/personnel', {
        exhibitorId: brandId,
        firstName: trimmedFirstName,
        lastName: trimmedLastName,
        jobTitle: trimmedJobTitle,
        companyName: trimmedCompanyName,
        city: trimmedCity,
        stateId: person.state_id,
        emailAddress: trimmedEmailAddress,
        phoneNumber: person.phone_number,
      })
      .then((res) => {
        BadgeConfirmationEmail(trimmedEmailAddress, {
          action: 'add',
          badgeFirstName: trimmedFirstName,
          badgeLastName: trimmedLastName,
          badgeJobTitle: trimmedJobTitle,
          badgeCompanyName: trimmedCompanyName,
          badgeCity: trimmedCity,
          badgeState: person.state,
          badgeEmailAddress: trimmedEmailAddress,
          badgePhoneNumber: person.phone_number,
          contactFirstName: userInfo.firstName,
          contactLastName: userInfo.lastName,
          contactCompanyName: userInfo.companyName,
        }).catch((err) => {
          console.error(err);
        });

        const badgeId = res.data[1][0].badge_id;
        dispatch(
          setExhibitors(
            exhibitors.map((brand) => {
              if (brand.id === brandId) {
                return {
                  ...brand,
                  personnel: brand.personnel.map((p) => {
                    if (p.badge_id === person.badge_id) {
                      return {
                        ...p,
                        badge_id: badgeId,
                        first_name: trimmedFirstName,
                        last_name: trimmedLastName,
                        job_title: trimmedJobTitle,
                        company_name: trimmedCompanyName,
                        city: trimmedCity,
                        email_address: trimmedEmailAddress,
                      };
                    } else {
                      return p;
                    }
                  }),
                };
              } else {
                return brand;
              }
            })
          )
        );
        setEditingRows(editingRows.filter((id) => id !== personnelId));
      })
      .catch((err) => {
        console.error('Failed to add personnel:', err);
      });
  };

  const updatePersonnelQuery = (brandId, personnelId) => {
    const person = exhibitors
      .find((brand) => brand.id === brandId)
      .personnel.find((person) => person.badge_id === personnelId);
    const trimmedFirstName = trimIfString(person.first_name);
    const trimmedLastName = trimIfString(person.last_name);
    const trimmedJobTitle = trimIfString(person.job_title);
    const trimmedCompanyName = trimIfString(person.company_name);
    const trimmedCity = trimIfString(person.city);
    const trimmedEmailAddress = trimIfString(person.email_address);

    axios
      .put('/rotr/exhibitors/personnel', {
        firstName: trimmedFirstName,
        lastName: trimmedLastName,
        jobTitle: trimmedJobTitle,
        companyName: trimmedCompanyName,
        city: trimmedCity,
        stateId: person.state_id,
        emailAddress: trimmedEmailAddress,
        phoneNumber: person.phone_number,
        badgeId: person.badge_id,
      })
      .then((res) => {
        BadgeConfirmationEmail(trimmedEmailAddress, {
          action: 'update',
          badgeFirstName: trimmedFirstName,
          badgeLastName: trimmedLastName,
          badgeJobTitle: trimmedJobTitle,
          badgeCompanyName: trimmedCompanyName,
          badgeCity: trimmedCity,
          badgeState: person.state,
          badgeEmailAddress: trimmedEmailAddress,
          badgePhoneNumber: person.phone_number,
          contactFirstName: userInfo.firstName,
          contactLastName: userInfo.lastName,
          contactCompanyName: userInfo.companyName,
        }).catch((err) => {
          console.error(err);
        });

        dispatch(
          setExhibitors(
            exhibitors.map((brand) => {
              if (brand.id === brandId) {
                return {
                  ...brand,
                  personnel: brand.personnel.map((p) => {
                    if (p.badge_id === person.badge_id) {
                      return {
                        ...p,
                        first_name: trimmedFirstName,
                        last_name: trimmedLastName,
                        job_title: trimmedJobTitle,
                        company_name: trimmedCompanyName,
                        city: trimmedCity,
                        email_address: trimmedEmailAddress,
                      };
                    } else {
                      return p;
                    }
                  }),
                };
              } else {
                return brand;
              }
            })
          )
        );
        setEditingRows(editingRows.filter((id) => id !== personnelId));
      })
      .catch((err) => {
        console.error('Failed to update personnel:', err);
      });
  };

  const deletePersonnelQuery = (brandId, personnelId) => {
    const person = exhibitors
      .find((brand) => brand.id === brandId)
      .personnel.find((person) => person.badge_id === personnelId);
    axios
      .delete('/rotr/exhibitors/personnel', {
        params: {
          badgeId: person.badge_id,
        },
      })
      .then((res) => {
        dispatch(
          setExhibitors(
            exhibitors.map((brand) =>
              brand.id === brandId
                ? {
                    ...brand,
                    personnel: brand.personnel.filter((person) => person.badge_id !== personnelId),
                  }
                : brand
            )
          )
        );
        setEditingRows(editingRows.filter((id) => id !== personnelId));
      })
      .catch((err) => {
        console.error('Failed to delete personnel:', err);
      });
  };

  const handleBoothEdit = (exhibitorId) => {
    axios
      .post(
        `/rotr/exhibitors/registrationData`,
        {},
        {
          params: {
            exhibitorId: exhibitorId,
          },
        }
      )
      .then((res) => {
        const data = res.data[0];

        const formattedData = {
          exhibitorId: data.rotr_exhibitor_id || '',
          companyName: data.exhibitor_name || '',
          companyEmail: data.exhibitor_email || '',
          companyPhone: data.exhibitor_phone || '',
          companyWebsite: data.exhibitor_website || '',
          boothNumber: data.booth_number || '',
          boothPrice: data.booth_price || '0.00',
          boothType: data.booth_type || '',
          paymentEmail: data.payment_email || '',
          paymentNotes: data.payment_notes || '',
          paymentTotal: data.payment_total || '0.00',
          paymentType: data.payment_type || '',
          addons: (data.addons || []).map((addon) => ({
            addon_id: addon.addon_id,
            image_url: addon.addon_image || '',
            price: addon.addon_price,
            addon_detailed_description: addon.addon_detail || '',
            show_limit: addon.addon_show_limit || 0,
            addon_description: addon.addon_description || '',
            quantity: addon.addon_qty_selected || 1,
            addon_type_description: addon.addon_type_description || '',
            exhibitor_limit: addon.addon_exhibitor_limit || 0,
            remaining_quantity: addon.remaining_quantity || '0',
            sold_quantity: addon.sold_quantity || '0',
          })),
          contentStep: 0,
          companyNameError: false,
          companyEmailError: false,
          companyPhoneError: false,
          isEditing: true,
        };
        dispatch(setExhibitorRegistration(formattedData));
      })
      .catch((error) => {
        console.error('Error fetching registration data:', error);
      });
  };

  // Updates badgeErrors for a given person
  // Returns boolean, whether values were valid or not
  const validateBadgeData = (person) => {
    const trimmedFirstName = trimIfString(person.first_name);
    const trimmedLastName = trimIfString(person.last_name);
    const trimmedCompanyName = trimIfString(person.company_name);
    const trimmedCity = trimIfString(person.city);
    const trimmedEmailAddress = trimIfString(person.email_address);
    const valid =
      trimmedFirstName &&
      trimmedLastName &&
      trimmedCompanyName &&
      trimmedCity &&
      person.state_id &&
      isValidEmail(trimmedEmailAddress) &&
      (!person.phone_number || isValidPhone(person.phone_number));
    setBadgeErrors((prev) => {
      return {
        ...prev,
        [person.badge_id]: {
          first_name: !trimmedFirstName,
          last_name: !trimmedLastName,
          company_name: !trimmedCompanyName,
          city: !trimmedCity,
          state_id: !person.state_id,
          email_address: !isValidEmail(trimmedEmailAddress),
          phone_number: !!person.phone_number && !isValidPhone(person.phone_number),
        },
      };
    });
    return valid;
  };

  // true = error, false = no error
  // if required, then set set to true if trimmed value is empty, otherwise false
  // if not required, then set to false
  const updateBadgeError = (badgeId, fieldName, newValue, required = false) => {
    if (badgeErrors.hasOwnProperty(fieldName)) {
      const trimmedValue = trimIfString(newValue);
      setBadgeErrors((prev) => {
        const otherErrors = prev[badgeId] ?? {};
        return {
          ...prev,
          [badgeId]: {
            ...otherErrors,
            [fieldName]: required && !trimmedValue,
          },
        };
      });
    }
  };

  // true = error, false = no error
  // if required, then set set to true if trimmed value is invalid email, otherwise false
  // if not required, then set to true if trimmed value is nonempty and invalid email, otherwise false
  const updateBadgeEmailError = (badgeId, newValue, required = false) => {
    const trimmedValue = trimIfString(newValue);
    setBadgeErrors((prev) => {
      const otherErrors = prev[badgeId] ?? {};
      return {
        ...prev,
        [badgeId]: {
          ...otherErrors,
          email_address: required
            ? !isValidEmail(trimmedValue)
            : !!trimmedValue && !isValidEmail(trimmedValue),
        },
      };
    });
  };

  // true = error, false = no error
  // if required, then set set to true if trimmed value is invalid phone, otherwise false
  // if not required, then set to true if trimmed value is nonempty and invalid phone, otherwise false
  const updateBadgePhoneError = (exhibitorId, badgeId, newValue, required = false) => {
    const trimmedValue = trimIfString(newValue);

    if (trimmedValue === '(___) ___-____') {
      dispatch(
        setExhibitors(
          exhibitors.map((exhibitor) =>
            exhibitor.id === exhibitorId
              ? {
                  ...exhibitor,
                  personnel: exhibitor.personnel.map((person) =>
                    person.badge_id === badgeId ? { ...person, phone_number: '' } : person
                  ),
                }
              : exhibitor
          )
        )
      );
      setBadgeErrors((prev) => {
        const otherErrors = prev[badgeId] ?? {};
        return {
          ...prev,
          [badgeId]: {
            ...otherErrors,
            phone_number: required,
          },
        };
      });
    } else {
      setBadgeErrors((prev) => {
        const otherErrors = prev[badgeId] ?? {};
        return {
          ...prev,
          [badgeId]: {
            ...otherErrors,
            phone_number: required
              ? !isValidPhone(trimmedValue)
              : !!trimmedValue && !isValidPhone(trimmedValue),
          },
        };
      });
    }
  };

  if (exhibitors.length === 0) {
    return null;
  }

  const handleProfileExplanationModal = (link) => {
    setSelectedLink(link);
    dispatch(setModalStatus({ profileExplanation: true }));
  };

  return (
    <>
      <Grid container item xs={12} ref={tableRef}>
        {isMobile ? (
          <ERegistrationTableMobile
            expandedBrandIds={expandedBrandIds}
            expandedPersonnelIds={expandedPersonnelIds}
            badgeErrors={badgeErrors}
            editingRows={editingRows}
            handleExpandClick={handleExpandClick}
            handleViewRegistrationModal={handleViewRegistrationModal}
            handleBoothEdit={handleBoothEdit}
            handleDeleteClick={handleDeleteClick}
            handleAddPersonnel={handleAddPersonnel}
            handlePersonnelExpandClick={handlePersonnelExpandClick}
            handlePersonnelInputChange={handlePersonnelInputChange}
            updateBadgeError={updateBadgeError}
            updateBadgeEmailError={updateBadgeEmailError}
            updateBadgePhoneError={updateBadgePhoneError}
            handleBadgeModal={handleBadgeModal}
            validateBadgeData={validateBadgeData}
            handleSave={handleSave}
            handleEdit={handleEdit}
            deletePersonnel={handleDeletePersonnelClick}
            states={states}
            handlePersonnelStateChange={handlePersonnelStateChange}
          />
        ) : (
          <ERegistrationTableDesktop
            badgeErrors={badgeErrors}
            editingRows={editingRows}
            handleViewRegistrationModal={handleViewRegistrationModal}
            handleBoothEdit={handleBoothEdit}
            handleDeleteClick={handleDeleteClick}
            handleAddPersonnel={handleAddPersonnel}
            handleBadgeModal={handleBadgeModal}
            handleEdit={handleEdit}
            deletePersonnel={handleDeletePersonnelClick}
            handlePersonnelInputChange={handlePersonnelInputChange}
            updateBadgeError={updateBadgeError}
            updateBadgeEmailError={updateBadgeEmailError}
            updateBadgePhoneError={updateBadgePhoneError}
            validateBadgeData={validateBadgeData}
            handleSave={handleSave}
            accordionExpanded={accordionExpanded}
            setAccordionExpanded={setAccordionExpanded}
            states={states}
            handlePersonnelStateChange={handlePersonnelStateChange}
            handleProfileExplanationModal={handleProfileExplanationModal}
          />
        )}
      </Grid>
      <ViewRegistrationModal
        isModalOpen={isViewModalOpen}
        handleClose={handleViewRegistrationModal}
        exhibitorId={selectedExhibitorId}
      />
      <BadgePreviewModal
        handleClose={handleBadgeModal}
        firstName={selectedPersonnel.first_name}
        lastName={selectedPersonnel.last_name}
        jobTitle={selectedPersonnel.job_title}
        companyName={selectedPersonnel.company_name}
        city={selectedPersonnel.city}
        state={selectedPersonnel.state}
      />
      <ConfirmDeleteModal
        open={isConfirmDeleteBrandModalOpen}
        handleClose={handleCancelDelete}
        handleDelete={handleConfirmDelete}
        bigText='Are you sure you want to delete this company?'
        smallText='This will delete all company information, including personnel and booth reservation.'
      />
      <ConfirmDeleteModal
        open={isConfirmDeletePersonnelModalOpen}
        handleClose={handleCancelDeletePersonnel}
        handleDelete={handleConfirmDeletePersonnel}
        bigText='Are you sure you want to delete this badge?'
      />
      <ProfileExplanationModal link={selectedLink} />
    </>
  );
};

export default ERegistrationTable;
