import axios from 'axios';
import React, { useEffect, useCallback } from 'react';
import {
  Grid,
  Checkbox,
  IconButton,
  Typography,
  Box,
  Card,
  CardContent,
  Divider,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { alpha } from '@mui/material/styles';
import ExhibitorInfo from './ExhibitorInfo';
import { useSelector, useDispatch } from 'react-redux';
import {
  setExhibitorRegistration,
  addAddon,
  removeAddon,
  updateAddonQuantity,
} from '../../../Redux/ExhibitorRegistrationSlice';

const RegistrationContent = () => {
  const dispatch = useDispatch();
  const selectedItems = useSelector((state) => state.registration.addons);
  const stepNumber = useSelector((state) => state.registration.contentStep);
  const content = useSelector((state) => state.registration.stepContent);
  const boothType = useSelector((state) => state.registration.boothType);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const contentMainImage = () => {
    const images = {
      1: 'https://s3.amazonaws.com/sportssouth.public/media_assets/rotr/addons/addon_panel.jpg',
      2: 'https://s3.amazonaws.com/sportssouth.public/media_assets/rotr/addons/preshow_panel.jpg',
      3: 'https://s3.amazonaws.com/sportssouth.public/media_assets/rotr/addons/directory_panel.jpg',
      4: 'https://s3.amazonaws.com/sportssouth.public/media_assets/rotr/addons/venue_digital_panel.jpg',
      5: 'https://s3.amazonaws.com/sportssouth.public/media_assets/rotr/addons/venue_panel.jpg',
      6: 'https://s3.amazonaws.com/sportssouth.public/media_assets/rotr/addons/wraps_panel.jpg',
    };
    return <img src={images[stepNumber]} alt={`Step ${stepNumber}`} style={{ maxWidth: '100%' }} />;
  };

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const res = await axios.get('rotr/e-registration/form/content');
        dispatch(setExhibitorRegistration({ stepContent: res.data }));
      } catch (err) {
        console.error('Failed to fetch content:', err);
      }
    };

    fetchContent();
  }, [dispatch]);

  const getContentForStepNumber = useCallback(() => {
    const addonTypeToStepNumber = {
      PRE_SHOW_ADS: 2,
      SHOW_DIRECTORY: 3,
      SHOW_DIGITAL_ADVERTISING: 4,
      SHOW_BANNERS: 5,
      SHOW_CLINGS_WRAPS: 6,
      BOOTH_ADDONS: 1,
    };
    for (const [addonType, number] of Object.entries(addonTypeToStepNumber)) {
      if (number === stepNumber) {
        return content[addonType] || [];
      }
    }
    return [];
  }, [content, stepNumber]);

  const handleCheckboxChange = (item, checked) => {
    if (checked) {
      dispatch(addAddon({ ...item, quantity: 1 }));
    } else {
      dispatch(removeAddon({ addon_id: item.addon_id }));
    }
  };

  const handleQuantityChange = (item, quantity) => {
    if (quantity > 0) {
      dispatch(updateAddonQuantity({ addon_id: item.addon_id, quantity }));
    } else {
      dispatch(removeAddon({ addon_id: item.addon_id }));
    }
  };

  const incrementQuantity = (item) => {
    const addon = selectedItems.find((addon) => addon.addon_id === item.addon_id);
    const newQuantity = (addon?.quantity || 0) + 1;
    handleQuantityChange(item, newQuantity);
  };

  const decrementQuantity = (item) => {
    const addon = selectedItems.find((addon) => addon.addon_id === item.addon_id);
    const newQuantity = (addon?.quantity || 0) - 1;
    handleQuantityChange(item, newQuantity);
  };

  const formatPrice = (price) => {
    return price.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
  };

  const stepContent = getContentForStepNumber();

  const isItemAvailable = (item) => {
    if (item.available === false) return false;
    if (item.show_limit !== 0 && item.remaining_quantity <= 0) return false;
    return true;
  };

  const showDefaultBoothQty = (item) => {
    if (boothType === 'Bronze' && item.default_bronze_qty > 0) {
      return item.default_bronze_qty;
    } else if (boothType === 'Silver' && item.default_silver_qty > 0) {
      return item.default_silver_qty;
    } else if (boothType === 'Gold' && item.default_gold_qty > 0) {
      return item.default_gold_qty;
    } else if (boothType === 'Platinum' && item.default_platinum_qty > 0) {
      return item.default_platinum_qty;
    }
    return null;
  };

  const getBoothTypeColor = (type) => {
    switch (type) {
      case 'Bronze':
        return '#8b5a2b';
      case 'Silver':
        return '#7f8c8d';
      case 'Gold':
        return '#b8860b';
      case 'Platinum':
        return '#6e7d8d';
      default:
        return 'black';
    }
  };

  const getContentTitle = (stepNumber) => {
    const stepTitles = {
      1: 'Booth Add-Ons',
      2: 'Pre-Show Advertising',
      3: 'Directory Advertising',
      4: 'Digital Advertising',
      5: 'Venue Banners',
      6: 'Venue Clings & Wraps',
    };
    return stepTitles[stepNumber];
  };

  return (
    <Box sx={{ padding: { xs: 1, md: 2 } }}>
      {stepNumber === 0 ? (
        <ExhibitorInfo />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ justifyContent: 'left', display: { xs: 'none', md: 'flex' } }}>
            <Typography variant='h5' sx={{ fontWeight: 'bold', color: '#00325a' }}>
              {getContentTitle(stepNumber)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            {stepContent.length > 0 ? (
              stepContent.map((item, index) => {
                const isChecked = selectedItems.some((addon) => addon.addon_id === item.addon_id);
                const available = isItemAvailable(item);
                const platinumSpecialCase = item.addon_id === 7 && boothType === 'Platinum';
                const exhibitorLimit =
                  item.show_limit !== 0 && item.remaining_quantity < item.exhibitor_limit
                    ? item.remaining_quantity
                    : item.exhibitor_limit;

                return (
                  <Card key={index} sx={{ marginBottom: 2 }}>
                    <CardContent sx={{ p: 1 }}>
                      <Grid container spacing={1} alignItems='center'>
                        <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                          {available && (
                            <Checkbox
                              checked={isChecked}
                              onChange={(e) => handleCheckboxChange(item, e.target.checked)}
                              sx={{
                                color: '#00325a',
                                '&.Mui-checked': {
                                  color: '#00325a',
                                },
                              }}
                              disabled={platinumSpecialCase}
                            />
                          )}
                        </Grid>
                        <Grid item xs container direction='column'>
                          <Typography
                            variant='body1'
                            sx={{ fontWeight: 700, textAlign: 'justify' }}
                          >
                            {item.addon_description}
                          </Typography>
                          <Typography variant='caption' sx={{ textAlign: 'justify' }}>
                            {item.addon_detailed_description}
                          </Typography>
                        </Grid>
                        {isMobile && (
                          <Grid
                            item
                            sx={{ visibility: 'hidden', display: 'flex', alignItems: 'center' }}
                          >
                            <Checkbox
                              sx={{
                                color: '#00325a',
                                '&.Mui-checked': {
                                  color: '#00325a',
                                },
                              }}
                            />
                          </Grid>
                        )}
                        <Grid
                          item
                          xs={isMobile ? 12 : 2}
                          container
                          direction='column'
                          alignItems='center'
                          sx={isMobile ? { textAlign: 'center' } : { ml: 0 }}
                        >
                          {platinumSpecialCase ? (
                            <>
                              <Typography
                                variant='body1'
                                sx={{ fontWeight: 'bold', color: '#00325a' }}
                              >{`$${formatPrice(Math.floor(item.price))}`}</Typography>
                              <Typography variant='body2' sx={{ color: '#d32f2f' }}>
                                Already <br /> Included
                              </Typography>
                            </>
                          ) : available ? (
                            <>
                              <Typography
                                variant='body1'
                                sx={{ fontWeight: 'bold', color: '#00325a' }}
                              >{`$${formatPrice(Math.floor(item.price))}`}</Typography>
                              <Typography variant='body2'>{`Limit: ${exhibitorLimit}`}</Typography>
                            </>
                          ) : (
                            <Typography variant='body1' sx={{ color: '#d32f2f', fontWeight: 700 }}>
                              Sold Out
                            </Typography>
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={isMobile ? 12 : 4}
                          container
                          justifyContent='center'
                          alignItems='center'
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              marginLeft: isMobile ? 0 : 2,
                            }}
                          >
                            {isChecked && available && (
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <IconButton
                                  onClick={() => decrementQuantity(item)}
                                  sx={{
                                    color: '#00325a',
                                    '&:hover': { backgroundColor: alpha('#00325a', 0.6) },
                                  }}
                                  disabled={
                                    selectedItems.find((addon) => addon.addon_id === item.addon_id)
                                      ?.quantity <= 1
                                  }
                                >
                                  <RemoveIcon />
                                </IconButton>
                                <Typography variant='body1' sx={{ mx: 1 }}>
                                  {selectedItems.find((addon) => addon.addon_id === item.addon_id)
                                    ?.quantity || 0}
                                </Typography>
                                <IconButton
                                  onClick={() => incrementQuantity(item)}
                                  sx={{
                                    color: '#00325a',
                                    '&:hover': { backgroundColor: alpha('#00325a', 0.6) },
                                  }}
                                  disabled={
                                    selectedItems.find((addon) => addon.addon_id === item.addon_id)
                                      ?.quantity >= exhibitorLimit
                                  }
                                >
                                  <AddIcon />
                                </IconButton>
                              </Box>
                            )}
                            {showDefaultBoothQty(item) > 0 && (
                              <Typography
                                variant='body2'
                                sx={{ color: 'black', textAlign: 'center' }}
                              >
                                <span style={{ fontWeight: 'bold' }}>
                                  {showDefaultBoothQty(item)}
                                </span>{' '}
                                already included with your <br />
                                <span
                                  style={{
                                    color: getBoothTypeColor(boothType),
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {boothType}
                                  {` `}
                                  Booth
                                </span>
                              </Typography>
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider />
                  </Card>
                );
              })
            ) : (
              <Typography variant='body1' sx={{ textAlign: 'center', color: '#aaa' }}>
                No items available for this step.
              </Typography>
            )}
          </Grid>
          {!isMobile && (
            <Grid
              item
              xs={12}
              md={6}
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              {contentMainImage()}
            </Grid>
          )}
        </Grid>
      )}
    </Box>
  );
};

export default RegistrationContent;
