import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import PDFDocument from '../_GlobalComponents/PDFDocument';
import { pdf } from '@react-pdf/renderer';
import axios from 'axios';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import rendezvousLogo from './Assets/rendezvousLogo.png';

const Invoice = () => {
  const [searchParams] = useSearchParams();
  const token = encodeURIComponent(searchParams.get('token') || '');
  const [invoiceData, setInvoiceData] = useState(null);
  const [numDownloads, setNumDownloads] = useState(0);
  const [blockButtonClick, setBlockButtonClick] = useState(false);

  const getInvoiceData = useCallback(() => {
    axios
      .get('/rotr/invoiceData', { params: { token: token } })
      .then((response) => {
        setInvoiceData(response.data);
      })
      .catch((error) => {
        setInvoiceData('error');
      });
  }, [token]);

  const handleDownload = useCallback(
    async (data) => {
      setBlockButtonClick(true);
      setNumDownloads(numDownloads + 1);
      const blob = await pdf(<PDFDocument data={data} />).toBlob({
        type: 'application/pdf',
      });
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
      setTimeout(() => setBlockButtonClick(false), 3000);
    },
    [numDownloads]
  );

  useEffect(() => {
    getInvoiceData();
  }, [getInvoiceData]);

  return (
    <Box
      sx={{
        mt: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <Box sx={{ display: 'flex', maxWidth: '50%', width: '500px', justifyContent: 'center' }}>
        <img src={rendezvousLogo} alt='RotR Logo' style={{ maxWidth: '100%', height: 'auto' }} />
      </Box>
      {invoiceData === null ? (
        <CircularProgress />
      ) : invoiceData === 'error' ? (
        <Typography variant='h5' sx={{ fontStyle: 'italic', color: '#222' }}>
          Error loading invoice. Please refresh and try again, or contact an administrator if this
          continues.
        </Typography>
      ) : invoiceData?.length > 0 ? (
        <>
          <Typography variant='h5' sx={{ color: '#222' }}>
            Invoice ready to download
          </Typography>
          <Button
            variant='contained'
            color='rendezvousRed'
            disabled={blockButtonClick}
            onClick={() => handleDownload(invoiceData)}
          >
            {blockButtonClick
              ? 'Download Initiated'
              : numDownloads > 0
              ? 'Redownload Invoice'
              : 'Download Invoice'}
          </Button>
        </>
      ) : (
        <Typography variant='h5' sx={{ fontStyle: 'italic', color: '#222' }}>
          No invoice available. If this is a mistake, please contact an administrator.
        </Typography>
      )}
    </Box>
  );
};

export default Invoice;
