import React from 'react';
import { Box, Button, Typography, useMediaQuery, Stepper, Step, StepLabel } from '@mui/material';
import { useTheme, alpha } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useDispatch, useSelector } from 'react-redux';
import { setExhibitorRegistration } from '../../../Redux/ExhibitorRegistrationSlice';
import { setModalStatus } from '../../../Redux/ModalSlice';

const RegistrationHeader = () => {
  const steps = [
    'Exhibitor Info',
    'Booth Add-Ons',
    'Pre-Show Advertising',
    'Directory Advertising',
    'Digital Advertising',
    'Venue Banners',
    'Venue Clings & Wraps',
  ];

  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const stepNumber = useSelector((state) => state.registration.contentStep);
  const registration = useSelector((state) => state.registration);

  return (
    <Box sx={{ width: '100%', height: '90px' }}>
      {isMobile ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            p: 2,
            bgcolor: '#f7f8fa',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            borderRadius: 1,
          }}
        >
          <Button
            onClick={() => dispatch(setExhibitorRegistration({ contentStep: stepNumber - 1 }))}
            disabled={stepNumber === 0}
            sx={{ color: '#00325a' }}
          >
            <ArrowBackIcon />
          </Button>
          <Typography
            variant='body2'
            sx={{ fontWeight: 'bold', textAlign: 'center', color: '#00325a', flex: 1 }}
          >
            {steps[stepNumber]}
          </Typography>
          {stepNumber === steps.length - 1 ? (
            <Button
              variant='contained'
              onClick={() => dispatch(setModalStatus({ confirmModal: true }))}
              sx={{
                backgroundColor: '#00325a',
                '&:hover': { backgroundColor: alpha('#00325a', 0.6) },
                width: '70px',
              }}
            >
              <Typography variant='body2' sx={{ fontSize: 12 }}>
                Confirm
              </Typography>
            </Button>
          ) : (
            <Button
              onClick={() => dispatch(setExhibitorRegistration({ contentStep: stepNumber + 1 }))}
              disabled={
                stepNumber === steps.length - 1 ||
                (stepNumber === 0 &&
                  (typeof registration.companyName !== 'string' ||
                    registration.companyName.trim() === '' ||
                    registration.companyEmailError ||
                    registration.companyPhoneError))
              }
              sx={{ color: '#00325a', '&:hover': { backgroundColor: alpha('#00325a', 0.6) } }}
            >
              <ArrowForwardIcon />
            </Button>
          )}
        </Box>
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Button
            variant='contained'
            onClick={() => dispatch(setExhibitorRegistration({ contentStep: stepNumber - 1 }))}
            disabled={stepNumber === 0}
            sx={{
              backgroundColor: '#00325a',
              '&:hover': { backgroundColor: alpha('#00325a', 0.6) },
              visibility: stepNumber === 0 ? 'hidden' : 'visible',
            }}
          >
            <ArrowBackIcon />
            Back
          </Button>
          <Stepper activeStep={stepNumber} alternativeLabel>
            {steps.map((label, index) => {
              return (
                <Step key={label}>
                  <StepLabel
                    sx={{
                      '& .MuiStepLabel-label': {
                        color: '#00325a',
                      },
                      '& .MuiStepLabel-label.Mui-active': {
                        fontWeight: 'bold',
                        fontSize: '1.1rem',
                      },
                      '& .MuiStepIcon-root': {
                        width: '26px',
                        height: '26px',
                        color: '#00325a',
                        '&.Mui-active': {
                          color: '#00325a',
                        },
                        '&.Mui-completed': {
                          color: 'green',
                        },
                        '& .MuiStepIcon-text': {
                          fontSize: '1.1rem',
                        },
                        '&:not(.Mui-active):not(.Mui-completed)': {
                          color: '#fff',
                          boxSizing: 'border-box',
                          '& .MuiStepIcon-text': {
                            fill: '#00325a',
                          },
                        },
                      },
                    }}
                  >
                    {label}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {stepNumber === steps.length - 1 ? (
            <Button
              variant='contained'
              onClick={() => dispatch(setModalStatus({ confirmModal: true }))}
              sx={{
                backgroundColor: '#00325a',
                '&:hover': { backgroundColor: alpha('#00325a', 0.6) },
              }}
            >
              Confirm
            </Button>
          ) : (
            <Button
              variant='contained'
              onClick={() => dispatch(setExhibitorRegistration({ contentStep: stepNumber + 1 }))}
              disabled={
                stepNumber === steps.length - 1 ||
                (stepNumber === 0 &&
                  (typeof registration.companyName !== 'string' ||
                    registration.companyName.trim() === '' ||
                    registration.companyEmailError ||
                    registration.companyPhoneError))
              }
              sx={{
                backgroundColor: '#00325a',
                '&:hover': { backgroundColor: alpha('#00325a', 0.6) },
                visibility: stepNumber === steps.length - 1 ? 'hidden' : 'visible',
              }}
            >
              Next
              <ArrowForwardIcon />
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
};

export default RegistrationHeader;
