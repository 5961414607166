import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { updateUserInformation } from '../../../Redux/UserInformationSlice';

const TravelSurvey = () => {
  const [content, setContent] = useState(null);
  const [isFlying, setIsFlying] = useState(null);
  const userInformation = useSelector((state) => state.userInformation.value);
  const [selectedAirport, setSelectedAirport] = useState('');
  const [flightDate, setFlightDate] = useState(null);
  const airlines = useMemo(
    () => ['Allegiant Airlines', 'American Airlines', 'Delta Air Lines', 'United Airlines'],
    []
  );
  const [selectedAirline, setSelectedAirline] = useState(null);
  const [numSeats, setNumSeats] = useState('1');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    axios
      .get(`/rotr/survey/content`)
      .then((res) => {
        setContent(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (content) {
      content.forEach((entry) => {
        const element = document.getElementById(entry.content_code);
        if (element) {
          element.innerHTML = entry.content;
        }
      });
    }
  }, [content]);

  const handleSubmit = () => {
    setOpenSnackbar(false);
    axios
      .post('/rotr/survey', {
        accessCode: userInformation.accessCode,
        isFlying: !!isFlying,
        airlineName: isFlying ? selectedAirline : null,
        airportName: isFlying ? selectedAirport.trim() : null, // Trim here
        seatsOccupied: isFlying ? numSeats : null,
        departureTime: isFlying ? dayjs(flightDate).format('YYYY-MM-DD') : null,
      })
      .then((res) => {
        dispatch(updateUserInformation({ hasCompletedSurvey: true }));
        setTimeout(() => {
          setSnackbarSeverity('success');
          setSnackbarMessage('Your survey was successfully submitted!');
          setOpenSnackbar(true);
        }, 250);
      })
      .catch((error) => {
        setTimeout(() => {
          setSnackbarSeverity('error');
          setSnackbarMessage('There was an issue with submitting the survey. Please try again.');
          setOpenSnackbar(true);
        }, 250);
      });
  };

  const seatOptions = useMemo(() => {
    const options = [];
    for (let i = 1; i <= 15; i++) {
      options.push(String(i));
    }
    return options;
  }, []);

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const isSubmitDisabled = useMemo(() => {
    if (isFlying === true) {
      return !selectedAirport.trim() || !flightDate?.isValid() || !numSeats || !selectedAirline;
    }
    return false;
  }, [isFlying, selectedAirport, flightDate, numSeats, selectedAirline]);

  return (
    <Box>
      <Grid container justifyContent='center' spacing={5}>
        <Grid item container xs={12} id='SURVEY_ROW1_COL1' />
        <Grid item container xs={12} mb={8}>
          {!userInformation.hasCompletedSurvey ? (
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 2,
              }}
            >
              <Typography
                variant='h6'
                component='div'
                align='center'
                sx={{
                  fontStyle: 'italic',
                  color: '#222',
                  mb: '24px',
                }}
              >
                Thank you for registering! Please take a moment to complete the short survey below.
              </Typography>
              <FormControl sx={{ mb: 3 }}>
                <FormLabel id='travel-method-label' focused={false} sx={{ p: 2, color: '#222' }}>
                  Will you be driving or flying?
                </FormLabel>
                <RadioGroup
                  aria-labelledby='travel-method-label'
                  name='travel-method-group'
                  value={isFlying}
                  onChange={(e) => setIsFlying(e.target.value === 'true')}
                >
                  <FormControlLabel value={false} control={<Radio />} label='Driving' />
                  <FormControlLabel value={true} control={<Radio />} label='Flying' />
                </RadioGroup>
              </FormControl>
              {isFlying === true && (
                <>
                  <Paper sx={{ p: 2, width: '100%', maxWidth: '900px' }}>
                    <Grid container justifyContent='center' alignItems='center' spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          label='Starting Airport'
                          placeholder='Please type the name of your airport here'
                          value={selectedAirport}
                          onChange={(e) => setSelectedAirport(e.target.value)}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <DatePicker
                          label='Flight Date'
                          value={flightDate}
                          onChange={(newValue) => {
                            setFlightDate(newValue);
                          }}
                          referenceDate={dayjs('2025-06-15')}
                          slotProps={{
                            textField: {
                              error: false,
                            },
                          }}
                          sx={{ width: '100%' }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Autocomplete
                          options={seatOptions}
                          getOptionLabel={(option) => option}
                          value={numSeats}
                          onChange={(event, newValue) => {
                            setNumSeats(newValue);
                          }}
                          disableClearable
                          renderInput={(params) => (
                            <TextField {...params} label='Select # of Seats' />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Autocomplete
                          options={airlines}
                          getOptionLabel={(option) => option}
                          value={selectedAirline}
                          onChange={(event, newValue) => {
                            setSelectedAirline(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label='Final Airline Upon Arrival' />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                          variant='contained'
                          color='rendezvousRed'
                          onClick={handleSubmit}
                          disabled={isSubmitDisabled}
                          sx={{ width: '100%', maxWidth: '125px', mt: 3 }}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </>
              )}
              {isFlying === false && (
                <Button
                  variant='contained'
                  color='rendezvousRed'
                  onClick={handleSubmit}
                  sx={{ width: '100%', maxWidth: '125px', mt: 1 }}
                >
                  Submit
                </Button>
              )}
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Typography
                variant='h6'
                component='div'
                align='center'
                sx={{
                  fontStyle: 'italic',
                  color: '#222',
                }}
              >
                Thank you for taking the time to complete this survey.
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default TravelSurvey;
