import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  styled,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import dayjs from 'dayjs';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: '#f9f9f9',
  boxShadow: theme.shadows[1],
  '&:not(:last-child)': {
    marginBottom: theme.spacing(2),
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: '#f9f9f9',
  border: '2px solid #cb351a',
  color: '#cb351a',
  '& .MuiAccordionSummary-content': {
    flexDirection: 'column',
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: 'black',
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const StyledButtonBase = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: '#cb351a',
  color: '#fff',
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: '#cb351a',
  },
}));

const Announcements = () => {
  const [content, setContent] = useState(null);
  const [announcements, setAnnouncements] = useState([]);

  useEffect(() => {
    axios
      .get(`/rotr/announcements/content`)
      .then((res) => {
        setContent(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`/rotr/announcements`)
      .then((res) => {
        setAnnouncements(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (content) {
      content.forEach((entry) => {
        const element = document.getElementById(entry.content_code);
        if (element) {
          element.innerHTML = entry.content;
        }
      });
    }
  }, [content]);

  return (
    <Box sx={{}}>
      <Grid container>
        <Grid item container xs={12} id='ANNOUNCEMENTS_ROW1_COL1' />
        <Grid item container xs={12} columnSpacing={2} alignItems='flex-start'>
          {announcements.length > 0 ? (
            announcements.map((announcement, index) => (
              <Grid key={index} item xs={12}>
                <StyledAccordion defaultExpanded>
                  <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant='h6' fontWeight='bold'>
                      {announcement.description || 'Announcement'}
                    </Typography>
                    <Typography sx={{ color: 'black' }}>
                      {dayjs(announcement.time_created).format('dddd, MMMM D, YYYY')}
                    </Typography>
                  </StyledAccordionSummary>
                  <StyledAccordionDetails>
                    <Typography>{announcement.detailed_description || 'Announcement'}</Typography>
                    {announcement.button_url && announcement.button_text && (
                      <StyledButtonBase
                        variant='contained'
                        onClick={() => {
                          if (announcement.button_new_tab) {
                            window.open(announcement.button_url, '_blank');
                          } else {
                            window.location = announcement.button_url;
                          }
                        }}
                      >
                        {announcement.button_text}
                      </StyledButtonBase>
                    )}
                  </StyledAccordionDetails>
                </StyledAccordion>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Typography
                variant='h5'
                component='div'
                align='center'
                sx={{
                  color: '#333',
                  mb: 2,
                  padding: '20px',
                  fontStyle: 'italic',
                }}
              >
                There are currently no announcements. Check back later!
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Announcements;
