import React, { useMemo, useState, useEffect } from 'react';
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  alpha,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import RendezvousLogo from '../../Assets/rendezvousLogo.png';
import { useNavigate } from 'react-router-dom';
import Countdown from './Countdown';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';

const Navbar = () => {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const userInformation = useSelector((state) => state.userInformation.value);
  const theme = useTheme();
  const isBelowXL = useMediaQuery(theme.breakpoints.down('xl'));
  const isBelowLG = useMediaQuery(theme.breakpoints.down('lg'));

  useEffect(() => {
    const resetBodyStyles = () => {
      document.body.style.paddingRight = '';
      document.body.style.overflow = '';
    };

    if (anchorElNav) {
      document.body.style.paddingRight = '0';
      document.body.style.overflow = 'auto';
    } else {
      resetBodyStyles();
    }

    return () => {
      resetBodyStyles();
    };
  }, [anchorElNav]);

  const pageLinks = useMemo(() => {
    switch (userInformation.userType) {
      case 'A1b2C3d4E5f6':
        return [
          { name: 'home', link: 'home' },
          { name: 'announcements', link: 'announcements', shimmer: true },
          { name: 'discover', link: 'discover' },
          { name: 'hotels', link: 'hotels' },
          { name: 'travel', link: 'flights' },
          { name: 'sponsors', link: 'sponsors' },
        ];
      case 'G7h8I9j0K1l2':
        return [
          { name: 'home', link: 'home' },
          { name: 'announcements', link: 'announcements', shimmer: true },
          { name: 'discover', link: 'discover' },
          { name: 'hotels', link: 'hotels' },
          { name: 'travel', link: 'flights' },
          { name: 'sponsors', link: 'sponsors' },
          { name: 'dashboard', link: 'exhibitors' },
        ];
      case 'M3n4O5p6Q7r8':
        return [
          { name: 'home', link: 'home' },
          { name: 'announcements', link: 'announcements', shimmer: true },
          { name: 'discover', link: 'discover' },
          { name: 'hotels', link: 'hotels' },
          { name: 'travel', link: 'flights' },
          { name: 'sponsors', link: 'sponsors' },
          { name: 'dashboard', link: 'attendees' },
        ];
      default:
        return [
          { name: 'home', link: 'home' },
          { name: 'announcements', link: 'announcements', shimmer: true },
          { name: 'discover', link: 'discover' },
          { name: 'hotels', link: 'hotels' },
          { name: 'travel', link: 'flights' },
          { name: 'sponsors', link: 'sponsors' },
        ];
    }
  }, [userInformation.userType]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleNavigate = (page) => {
    if (page === 'home') {
      navigate('/');
    } else {
      navigate(`/${page}`);
    }
    handleCloseNavMenu();
  };

  return (
    <AppBar
      position='static'
      sx={{ backgroundColor: '#ffffff', borderBottom: '4px solid #cb351a' }}
    >
      <Box maxWidth='100%' sx={{ p: 0, overflowX: 'auto' }}>
        <Toolbar
          disableGutters
          sx={{
            justifyContent: 'space-between',
            alignItems: 'stretch',
            maxWidth: '1600px',
            m: '0 auto',
            px: isBelowLG ? 2 : 0,
            flexDirection: { xs: 'row-reverse', lg: 'row' },
          }}
        >
          {/* third invisible element, to center logo when screen is small enough */}
          <Box sx={{ display: { xs: 'flex', lg: 'none' }, width: '48px' }} />

          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', lg: 'row' },
              flexGrow: { xs: 0, lg: 1 },
              alignItems: 'center',
            }}
          >
            <Typography
              variant='h6'
              noWrap
              component='div'
              onClick={() => navigate('/')}
              sx={{
                color: '#00325a',
                textDecoration: 'none',
                m: 1,
                cursor: 'pointer',
              }}
            >
              <img
                src={RendezvousLogo}
                alt='Rendezvous on the Red'
                style={{ width: 'auto', height: 'auto', maxHeight: '60px', display: 'block' }}
              />
            </Typography>
            <Typography
              variant='h6'
              noWrap
              component='div'
              sx={{
                color: '#00325a',
                textDecoration: 'none',
                m: 1,
                pl: 3,
                display: { xs: 'none', xl: 'block' },
              }}
            >
              <img
                src='https://s3.amazonaws.com/sportssouth.public/media_assets/rotr/duck_only_blue.png'
                alt='Sports South'
                style={{ width: 'auto', height: 'auto', maxHeight: '60px', display: 'block' }}
              />
            </Typography>
            <Box sx={{ mx: 'auto' }}>
              <Countdown showTimer={!(isBelowXL && !isBelowLG)} />
            </Box>
          </Box>

          <Box sx={{ display: { xs: 'flex', lg: 'none' }, alignItems: 'center' }}>
            <IconButton
              size='large'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleOpenNavMenu}
              color='inherit'
            >
              <MenuIcon sx={{ color: '#00325a' }} />
            </IconButton>
            <Menu
              id='menu-appbar'
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', lg: 'none' },
                '& ul': {
                  py: 0,
                },
                '& .MuiPaper-root': {
                  marginTop: 0,
                },
                '& li:last-of-type': { borderBottom: 0 },
              }}
            >
              {pageLinks.map(({ name, link }) => (
                <MenuItem
                  onClick={() => handleNavigate(link)}
                  sx={{
                    py: '14px',
                    color: '#00325a',
                    '&:hover': {
                      backgroundColor: '#cb351a',
                      color: '#ffffff',
                    },
                    borderBottom: 'solid 1px #ccc',
                  }}
                  key={link}
                >
                  <Typography
                    sx={{
                      textTransform: 'uppercase',
                      textAlign: 'center',
                      fontWeight: 'bold',
                    }}
                  >
                    {name}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box sx={{ display: { xs: 'none', lg: 'flex' } }}>
            {pageLinks.map(({ name, link, shimmer }) => (
              <Box
                key={link}
                sx={{
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Button
                  component={motion.button}
                  whileHover={{
                    outline:
                      name !== 'dashboard' && name !== 'sponsors'
                        ? '2px solid transparent'
                        : 'none',
                    transition: { duration: 0.1 },
                    outlineOffset: name !== 'dashboard' && name !== 'sponsors' ? '2px' : '0px',
                    outlineWidth:
                      name !== 'dashboard' && name !== 'sponsors'
                        ? ['0px', '2px', '2px', '2px']
                        : '0px',
                    outlineColor:
                      name !== 'dashboard' && name !== 'sponsors'
                        ? ['#cb351a', '#cb351a', '#cb351a', '#cb351a']
                        : 'none',
                  }}
                  onClick={() => handleNavigate(link)}
                  sx={{
                    color: name === 'dashboard' || name === 'sponsors' ? '#ffffff' : '#cb351a',
                    backgroundColor:
                      name === 'dashboard'
                        ? '#00325a'
                        : name === 'sponsors'
                        ? '#cb351a'
                        : 'transparent',
                    fontWeight: 'bold',
                    '&:hover': {
                      backgroundColor:
                        name === 'dashboard'
                          ? alpha('#00325a', 0.7)
                          : name === 'sponsors'
                          ? alpha('#cb351a', 0.7)
                          : 'transparent',
                      color: name === 'dashboard' || name === 'sponsors' ? '#ffffff' : '#cb351a',
                    },
                    mx: 0.5,
                    ...(shimmer && {
                      position: 'relative',
                      overflow: 'hidden',
                      '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        background: 'linear-gradient(to right, transparent, #fff, transparent)',
                        transform: 'translateX(-100%)',
                        animation: 'shimmer 2s linear infinite',
                      },
                      '@keyframes shimmer': {
                        '100%': {
                          transform: 'translateX(100%)',
                        },
                      },
                    }),
                  }}
                >
                  <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                    {name}
                  </Typography>
                </Button>
              </Box>
            ))}
          </Box>
        </Toolbar>
      </Box>
    </AppBar>
  );
};

export default Navbar;
