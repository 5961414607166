import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, Grid, styled, CardContent, Typography, Box, Button } from '@mui/material';
import {
  AppRegistration,
  Badge,
  Checklist,
  Help,
  LocalAirport,
  Newspaper,
  NightShelter,
  PivotTableChart,
  Place,
  Quiz,
  ViewQuilt,
  Work,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setModalStatus } from '../../../../Redux/ModalSlice';
import Ads from '../../Ads';

const StyledCard = styled(({ ...props }) => <Card elevation={3} {...props} />)(({ theme }) => ({
  maxWidth: 400,
  borderRadius: theme.spacing(2),
  boxShadow: theme.shadows[3],
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

const StyledIcon = styled(({ component: Component, ...props }) => <Component {...props} />)(
  ({ theme }) => ({
    fontSize: 100,
    color: '#cb351a',
    margin: '20px auto 10px',
    display: 'block',
  })
);

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(2),
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: '#cb351a',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#cb351a',
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  lineHeight: 1.5,
  justifyContent: 'center',
}));

const Attendees = () => {
  const [content, setContent] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleBoothStaffClick = () => {
    dispatch(setModalStatus({ attendeeBadges: true }));
    navigate('/a-register');
  };

  const gridItems = [
    {
      icon: AppRegistration,
      header: 'Registration',
      description: 'Register now to secure your spot at our Summit of the Year.',
      buttonFunction: () => navigate('/a-register'),
      buttonText: 'Manage',
    },
    {
      icon: Badge,
      header: 'Manage Badges',
      description: 'Manage access badges for you and your guests.',
      buttonFunction: handleBoothStaffClick,
      buttonText: 'Manage',
    },
    {
      icon: Newspaper,
      header: 'Announcements',
      description: 'Stay up to date with the latest announcements and important event updates.',
      buttonFunction: () => navigate('/announcements'),
      buttonText: 'View',
    },
    {
      icon: Work,
      header: 'Exhibitors',
      description: 'Explore the list of exhibitors who have confirmed their participation.',
      buttonFunction: () => navigate('/confirmed-exhibitors'),
      buttonText: 'Explore',
    },
    {
      icon: ViewQuilt,
      header: 'Floor Plan',
      description: 'Explore the floor plan and start mapping out your perfect show experience.',
      buttonFunction: () => navigate('/floor-plan'),
      buttonText: 'Explore',
    },
    {
      icon: Help,
      header: 'Attendee Help',
      description:
        'Quickly find answers or get contact information for people who can assist you with common questions.',
      buttonFunction: () => navigate('/attendee-help'),
      buttonText: 'View',
    },
    {
      icon: Checklist,
      header: 'Itinerary',
      description: 'View the complete show itinerary and plan your schedule.',
      buttonFunction: () => navigate('/attendee-itinerary'),
      buttonText: 'View',
    },
    // {
    //   icon: PivotTableChart,
    //   header: 'Floor Plan',
    //   description: 'Explore the detailed floor plan to easily navigate the event.',
    //   buttonFunction: () => navigate('/attendee-resource-center'),
    //   buttonText: 'Explore',
    // },
    {
      icon: NightShelter,
      header: 'Hotels',
      description:
        'Browse hotels offering exclusive discounted rates for Rendezvous on the Red attendees.',
      buttonFunction: () => navigate('/hotels'),
      buttonText: 'Browse',
    },
    // {
    //   icon: LocalAirport,
    //   header: 'Travel',
    //   description: 'Explore nearby airports and find essential details to plan your travel.',
    //   buttonFunction: () => navigate('/flights'),
    //   buttonText: 'Explore',
    // },
    // {
    //   icon: Place,
    //   header: 'Discover Shreveport',
    //   description: 'Discover the venue, downtown district, and local attractions.',
    //   buttonFunction: () => {
    //     navigate('/discover');
    //     window.scrollTo(0, 0); // To fix corner case of being half-scrolled after navigation
    //   },
    //   buttonText: 'Discover',
    // },
    {
      icon: Quiz,
      header: 'FAQs',
      description: 'Find answers to commonly asked questions.',
      buttonFunction: () => navigate('/attendee-faq'),
      buttonText: 'Find Answers',
    },
  ];

  useEffect(() => {
    axios
      .get('/rotr/attendees/content')
      .then((res) => {
        setContent(res.data);
      })
      .catch((err) => {
        console.error('Failed to fetch content:', err);
      });
  }, []);

  useEffect(() => {
    if (content.length > 0) {
      content.forEach((entry) => {
        const element = document.getElementById(entry.content_code);
        if (element) {
          element.innerHTML = entry.content;
        }
      });
    }
  }, [content]);

  return (
    <Box sx={{}}>
      <Grid container spacing={2} justifyContent='center' alignItems='flex-start'>
        <Grid item container xs={12} md={12} id='ATTENDEES_ROW1_COL1' />
        <Grid item container xs={12} md={9} spacing={2}>
          {gridItems.map((item) => (
            <Grid item xs={12} md={4} key={item.header}>
              <StyledCard>
                <Box sx={{ height: 150, backgroundColor: '#fff' }}>
                  <StyledIcon component={item.icon} />
                </Box>
                <StyledCardContent>
                  <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                    <StyledTypography variant='h6' sx={{ fontWeight: 'bold' }}>
                      {item.header}
                    </StyledTypography>
                    <Typography variant='body2' align='center' flexGrow={1}>
                      {item.description}
                    </Typography>
                  </Box>
                  <Box display='flex' justifyContent='center'>
                    <StyledButton variant='contained' onClick={item.buttonFunction}>
                      {item.buttonText}
                    </StyledButton>
                  </Box>
                </StyledCardContent>
              </StyledCard>
            </Grid>
          ))}
        </Grid>
        <Grid item container xs={12} md={3} spacing={4}>
          <Ads numberToShow={6} gridXS={12} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Attendees;
