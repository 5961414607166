import './App.css';
import axios from 'axios';
import RendezvousOnTheRed from './RendezvousOnTheRed/RendezvousOnTheRed';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Route, Routes } from 'react-router-dom';
import Invoice from './RendezvousOnTheRed/Invoice';
import MarketingOpportunities from './RendezvousOnTheRed/Components/MarketingOpportunities/MarketingOpportunities';

function App() {
  if (window.location.host !== 'localhost:3000') {
    axios.defaults.baseURL = 'https://ninja.sportssouth.dev';
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Routes>
        <Route path='/invoice' element={<Invoice />} />
        <Route path='/marketing-opportunities' element={<MarketingOpportunities />} />
        <Route path='*' element={<RendezvousOnTheRed />} />
      </Routes>
    </LocalizationProvider>
  );
}

export default App;
